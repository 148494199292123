import { createReducer, on } from '@ngrx/store';
import { GoFishActions } from './go-fish.actions';
import { GoFishState, initialState } from './go-fish.state';

const goFishToggleGameActive = (state: GoFishState, action) => {
  return {
    ...state,
    isGameActive: action.payload.isGameActive,
  };
};

const goFishUpdateCardDisplay = (state: GoFishState, action) => {
  return {
    ...state,
    cardDisplay: action.payload.cardDisplay,
  };
};

const goFishUpdateDealCount = (state: GoFishState, action) => {
  return {
    ...state,
    dealCount: action.payload.dealCount,
  };
};

const goFishXrayStatus = (state: GoFishState, action) => {
  return {
    ...state,
    xRayOn: action.payload.xRayOn,
  };
};

const goFishUpdateCards = (state: GoFishState, action) => {
  return {
    ...state,
    cards: action.payload.cards,
  };
};

export const reducer = createReducer(
  initialState,
  on(GoFishActions.goFishUpdateCardDisplay, goFishUpdateCardDisplay),
  on(GoFishActions.goFishUpdateDealCount, goFishUpdateDealCount),
  on(GoFishActions.goFishXrayStatus, goFishXrayStatus),
  on(GoFishActions.goFishToggleGameActive, goFishToggleGameActive),
  on(GoFishActions.goFishUpdateCards, goFishUpdateCards),
);
