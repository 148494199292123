import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PageTitleStrategyService extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }
  override updateTitle(routerState: RouterStateSnapshot): void {
    const isLibrary = routerState.url.includes('/library');
    const defaultTitle = isLibrary ? 'Library' : 'Room';
    let title = this.buildTitle(routerState) || defaultTitle;
    const titleSuffix = ' - Presence';

    // Library custom generated titles
    if (isLibrary) {
      // Assessment routes

      if (routerState.url.includes('/assessments')) {
        title = 'Assessments';
        if (routerState.url.includes('/preview')) {
          title += ' - Preview';
        }
        let lastChild = routerState.root;
        let maxIterations = 10;
        while (lastChild.firstChild && maxIterations > 0) {
          lastChild = lastChild.firstChild;
          maxIterations--;
        }

        if (lastChild.data.test) {
          title += ` - ${lastChild.data.test.name}`;
        }

        if (lastChild.data.assessmentCategory) {
          title += ` - ${lastChild.data.assessmentCategory.name}`;
        }

        if (lastChild.data.assessmentPublisher) {
          title += ` - ${lastChild.data.assessmentPublisher.name}`;
        }
      }
    }

    this.title.setTitle(`${title}${titleSuffix}`);
  }
}
