import { AppState } from '@app/store';
import { createSelector } from '@ngrx/store';
import { ActivityType, CentralContentType, LayoutMode } from './app.model';
import { PLVendorGamesByActivity } from '../../pl-activity/game/vendor-games/pl-vendor-game-definitions.service';
export const selectAppConfig = (state: AppState) => state.app;

export const selectSharedCursorOn = createSelector(
  selectAppConfig,
  app => app.isCursorShared,
);

export const selectClientClickMuted = createSelector(
  selectAppConfig,
  app => app.isClientClickMuted,
);

export const selectSharedCursorToggleDisabled = createSelector(
  selectAppConfig,
  app => app.isToggleDisabled,
);

export const selectLayoutMode = createSelector(
  selectAppConfig,
  app => app.layoutMode,
);

export const selectIsLayoutModeFullScreen = createSelector(
  selectLayoutMode,
  layoutMode => layoutMode !== LayoutMode.compact,
);

export const selectIsCentralContentVisible = createSelector(
  selectAppConfig,
  app => app.isCentralContentVisible,
);

export const selectCurrentCentralContent = createSelector(
  selectAppConfig,
  app => app.currentCentralContent,
);

export const selectCurrentCentralContentType = createSelector(
  selectCurrentCentralContent,
  content => content?.contentType,
);

export const selectCurrentCentralContentDetail = createSelector(
  selectCurrentCentralContent,
  content => content?.contentDetail,
);

export const selectCurrentCentralContentActivityType = createSelector(
  selectCurrentCentralContentDetail,
  detail => detail?.activity_type,
);

export const selectCentralContentDetailType = createSelector(
  selectCurrentCentralContentDetail,
  detail => detail?.type,
);

export const selectCurrentDrawerActivity = createSelector(
  selectAppConfig,
  app => app.currentDrawerActivity,
);

export const selectIsCentralContentEmpty = createSelector(
  selectCurrentCentralContentType,
  contentType => contentType === CentralContentType.Empty,
);

export const selectIsCentralContentContentShare = createSelector(
  selectCurrentCentralContentType,
  contentType => contentType === CentralContentType.ContentShare,
);

export const selectIsCentralContentQueueItem = createSelector(
  selectCurrentCentralContentType,
  contentType => contentType === CentralContentType.QueueItem,
);

export const selectIsCurrentCentralContent = (contentType, contentDetail) =>
  createSelector(
    selectCurrentCentralContent,
    currentCentralContent =>
      currentCentralContent?.contentType === contentType &&
      ((contentType !== CentralContentType.QueueItem &&
        contentType !== CentralContentType.Game) ||
        (contentType === CentralContentType.QueueItem &&
          currentCentralContent?.contentDetail?.activityId ===
            contentDetail?.activityId) ||
        (contentType === CentralContentType.Game &&
          currentCentralContent?.contentDetail?.name === contentDetail?.name)),
  );

export const selectIsCentralContentAssessment = createSelector(
  selectCurrentCentralContentType,
  selectCurrentCentralContentActivityType,
  (type, activityType) =>
    type === CentralContentType.QueueItem && activityType === 'assessment',
);

export const selectIsCentralContentVendorGame = createSelector(
  selectCurrentCentralContentType,
  selectCurrentCentralContentActivityType,
  selectCentralContentDetailType,
  (type, activityType, activityDetailType) =>
    type === CentralContentType.QueueItem &&
    activityType === ActivityType.GAME &&
    Boolean(PLVendorGamesByActivity[activityDetailType]),
);

export const selectIsWhiteboardEnabledContent = createSelector(
  selectCurrentCentralContentType,
  contentType =>
    [
      CentralContentType.Empty,
      CentralContentType.QueueItem,
      CentralContentType.ContentShare,
      CentralContentType.Game,
    ].includes(contentType),
);

export const selectShouldUseBigHandCursor = createSelector(
  selectIsCentralContentVisible,
  selectIsCentralContentAssessment,
  (isVisible, isAssessment) => isVisible && isAssessment,
);

export const selectIsTeamwriteActive = createSelector(
  selectCurrentCentralContentType,
  type => type === CentralContentType.Teamwrite,
);

export const selectIsWhiteboardDisabled = createSelector(
  selectIsWhiteboardEnabledContent,
  selectIsCentralContentVisible,
  (isWhiteboard, isVisible) => !isWhiteboard && isVisible,
);

export const selectIsCentralContentBoardGame = createSelector(
  selectCurrentCentralContentType,
  type => type === CentralContentType.Game,
);

export const selectCentralContentBoardGame = createSelector(
  selectIsCentralContentBoardGame,
  selectCurrentCentralContentDetail,
  (isBoardGame, boardGame) => (isBoardGame ? boardGame : null),
);

export const selectIsCentralContentYoutube = createSelector(
  selectIsCentralContentQueueItem,
  selectCentralContentDetailType,
  (isQueueItem, type) =>
    isQueueItem && (type === 'youtube' || type === 'instant_youtube'),
);

export const selectIsYoutubeActive = createSelector(
  selectIsCentralContentYoutube,
  selectIsCentralContentVisible,
  (isYoutube, isVisible) => isYoutube && isVisible,
);

export const selectIsCentralContentSiteshare = createSelector(
  selectCurrentCentralContentType,
  type => type === CentralContentType.SiteShare,
);

export const selectIsTimestampTrackingDisabled = createSelector(
  selectAppConfig,
  app => app.isTimestampTrackingDisabled,
);
