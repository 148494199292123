import { pipe } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { filter, map } from 'rxjs/operators';
import {
  selectIsLocalParticipantGuest,
  selectIsLocalParticipantHost,
  selectSessionInitialized,
} from './session.selectors';
import { concatLatestFrom } from '@ngrx/effects';

export const filterSessionReady = pipe(
  select(selectSessionInitialized),
  filter(initialized => !!initialized),
);

export const filterHostSessionReady = (store: Store<AppState>) =>
  pipe(
    filterSessionReady,
    concatLatestFrom(() => store.select(selectIsLocalParticipantHost)),
    map(([, isHost]) => isHost),
    filter(isHost => !!isHost),
  );

export const filterGuestSessionReady = (store: Store<AppState>) =>
  pipe(
    filterSessionReady,
    concatLatestFrom(() => store.select(selectIsLocalParticipantGuest)),
    map(([, isGuest]) => isGuest),
    filter(isGuest => !!isGuest),
  );
