export interface User {
  uuid: string;
  first_name: string;
  last_name: string;
  created?: string;
  last_login?: string;
  display_name: string;
  username?: string;
  userStatus?: UserStatus;
  email?: string;
  groups?: string[];
  isObserver?: boolean;
  admissionInfo?: AdmissionInfo;
  profile_image_url?: string;
  xAuthPermissions?: string[];
  xPermissions?: Record<string, boolean>;
  xGlobalPermissions?: Record<string, boolean>;
  xEnabledUiFlags?: string[];
  xProvider?: Provider;
}

export interface AdmissionInfo {
  joinMuted: boolean;
}

export enum PLEmploymentStatus {
  FULL_TIME_EMPLOYEE = 'Full-Time Employee',
  PART_TIME_EMPLOYEE = 'Part-Time Employee',
  SALARIED_EMPLOYEE = 'Salaried Employee',
  INDEPENDENT_CONTRACTOR = 'Independent Contractor',
  SUBCONTRACTOR = 'Subcontractor',
}

export interface UserStatus {
  aud: string;
  auth_time: number;
  exp: number;
  iat: number;
  iss: string;
  plru: string;
  sub: string;
}
export interface Provider {
  uuid: string;
  created: Date;
  modified: Date;
  is_active: boolean;
  user: string;
  salesforce_id: string;
  provider_types: string[];
  phone: string;
  billing_street: string;
  billing_city: string;
  billing_postal_code: string;
  billing_state: string;
  billing_country: string;
  first_name: string;
  last_name: string;
  timezone: string;
  username: string;
  email: string;
  caseload_clients_count?: number;
  in_retainer_program: boolean;
  bill_as_employee: boolean;
  is_onboarding_wizard_complete: boolean;
  employment_status?: PLEmploymentStatus;
  provider_sub_status?: string;
}
