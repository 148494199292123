import { Injectable } from '@angular/core';
import { PLHijackHelperService } from '@common/services/pl-hijack.service';

/**
 * This global state object is for those Rare occasions
 * that you need to watch or manage global state
 * on root scope, e.g. when bootstrapping a user on
 * a top-level component.
 */
@Injectable({
  providedIn: 'root',
})
export class PlAppGlobalService {
  APP_GLOBAL = 'plAppGlobal';

  // put a state object on the root scope;
  _state: any = (window[this.APP_GLOBAL] = {});

  constructor(private plHijackHelper: PLHijackHelperService) {}

  // global user
  setUser = user => {
    this._state.user = user;
    this._state.isHijacked = this.plHijackHelper.isUserHijacked(
      this._state.user,
    );
  };

  getUser = () => {
    return this._state.user;
  };

  // special case of student access - e.g. in the room
  setStudentMode = (val = true) => {
    this._state.studentMode = val;
  };

  isStudentMode = user => {
    if (user) {
      return user.isInGroup('student');
    }
    return this._state.studentMode;
  };

  // special case of observer access
  setObserverMode = (val = true) => {
    this._state.observerMode = val;
  };

  isObserverMode = () => {
    return this._state.observerMode;
  };

  isAuthenticated = user => {
    const USER = user || this._state.user;
    return USER.isAuthenticated;
  };

  isGuest = () => {
    return !this._state.user.groups || this._state.user.groups.length === 0;
  };

  isUserHijacked = () => {
    console.log('-- user', this._state.user);
    console.log(
      '-- ishijacked',
      this.plHijackHelper.isUserHijacked(this._state.user),
    );
    return (
      this._state.user && this.plHijackHelper.isUserHijacked(this._state.user)
    );
  };

  getWindowGlobal = () => {
    return window.roomGlobal;
  };

  setIsTechCheck = (isTechCheck: boolean) =>
    (this._state.isTechCheck = isTechCheck);

  isLibrary = () => this._state.isLibrary;

  setIsLibrary = (isLibrary: boolean) => (this._state.isLibrary = isLibrary);
}
