import { Injectable } from '@angular/core';
import { PLGuidService } from './GuidService';

const BROWSER_ID_KEY = 'pl-browser-id';

@Injectable({ providedIn: 'root' })
export class BrowserIdService {
  readonly browserId: string;

  constructor(private guidService: PLGuidService) {
    const existingId = localStorage.getItem(BROWSER_ID_KEY);
    this.browserId = existingId ?? this.createBrowserId();
  }

  private createBrowserId() {
    const newId = this.guidService.generateUUID();
    localStorage.setItem(BROWSER_ID_KEY, newId);
    return newId;
  }
}
