import { Injectable } from '@angular/core';
import { concatLatestFrom, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { EMPTY, of } from 'rxjs';
import { delay, filter, map, switchMap } from 'rxjs/operators';

import { NetworkStatus, NetworkStatusService } from '../../services';
import * as WindowActions from '../window.actions';
import * as fromWindow from '../window.selectors';

@Injectable({ providedIn: 'root' })
export class WindowNetworkStatusEffects {

  networkStatusChanged$ = createEffect(() => {
    return this.networkStatusService.getValue().pipe(
      concatLatestFrom(() => this.store.select(fromWindow.selectNetworkStatus)),
      filter(([status, prevStatus]) => status !== prevStatus),
      map(([status, prevStatus]) => {
        let networkStatus = status;
        const wasOffline = prevStatus === NetworkStatus.offline;
        const isOnline = status === NetworkStatus.online;

        if (wasOffline && isOnline) {
          networkStatus = NetworkStatus.reconnecting;
        }

        return WindowActions.networkStatusChanged({
          networkStatus,
        });
      }),
    );
  });

  reconnected$ = createEffect(() => {
    return this.store.select(fromWindow.selectIsReconnecting).pipe(
      switchMap((isReconnecting) => {
        if (!isReconnecting) {
          return EMPTY;
        }

        return of(
          WindowActions.networkStatusChanged({
            networkStatus: NetworkStatus.online,
          }),
        ).pipe(delay(5_000));
      }),
    );
  });

  constructor(
    private store: Store<AppState>,
    private networkStatusService: NetworkStatusService,
  ) {}
}
