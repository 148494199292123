import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { PLUrlsService } from '@common/services/pl-http';
import { environment } from '../environments/environment';
import { User } from './modules/user/user.model';
import { AppState } from './store';
import { selectCurrentUser } from './modules/user/store';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  apps: any = environment.apps;
  currentUser: User;

  constructor(private plUrls: PLUrlsService, store: Store<AppState>) {
    store.select(selectCurrentUser).subscribe((user: any) => {
      if (!user) {
        return;
      }
      this.currentUser = user;
    });
    this.checkForConfig();
    this.formUrls(this.apps);
  }

  checkForConfig() {
    if (typeof _envFileConfig !== 'undefined' && _envFileConfig.apps) {
      this.apps = Object.assign(this.apps, _envFileConfig.apps);
      console.log('Using envConfigFile, apps:', this.apps);
      this.formUrls(this.apps);
    }
  }

  formUrls(apps1: any = null) {
    let urls = {};
    if (!apps1) {
      apps1 = this.apps;
    }
    if (apps1) {
      let apps = [
        'auth',
        'apiWorkplace',
        'platform',
        'library',
        'eduClients',
        'apiEssentials',
        'therapy',
        'learnupon',
        'apiMizar',
        'chiron',
      ];
      const appUrls: any = {};
      apps.forEach((app: any) => {
        appUrls[app] = apps1[app] && apps1[app].url ? apps1[app].url : '';
      });
      urls = {
        login: `${environment.login_url}/login/`,
        logout: `${environment.login_url}/logout/`,
        assumeLogin: `${environment.login_url}/hijack/email/`,
        releaseLogin: `${environment.login_url}/hijack/release-hijack/`,
        changePasswordFE: `${environment.login_url}/password/change`,
        telehealthInstituteFE: `${environment.login_url}/saml/sso/learnupon?sp=${appUrls.learnupon}&RelayState=Dashboard`,
        status: `${environment.login_url}/api/v1/status/`,
        clientTokenUrl: `${appUrls.auth}/api/v2/users/generate_client_token/`,
        user: `${appUrls.auth}/api/v1/user/`,
        users: `${appUrls.auth}/api/v2/users/`,
        permissions: `${appUrls.auth}/api/v1/permissions/`,

        workplaceFE: `${appUrls.apiWorkplace}`,
        activities: `${appUrls.apiWorkplace}/api/v1/activities/`,
        appointments: `${appUrls.apiWorkplace}/api/v3/appointments/`,
        assignments: `${appUrls.apiWorkplace}/api/v1/assignments/`,
        assignmentsAdd: `${appUrls.apiWorkplace}/api/v1/assignments/add/`,
        assignmentsRemove: `${appUrls.apiWorkplace}/api/v1/assignments/remove/`,
        areasOfConcern: `${appUrls.apiWorkplace}/api/v1/areas-of-concern/`,
        assessments: `${appUrls.apiWorkplace}/api/v1/assessments/`,
        availabilitySettings: `${appUrls.apiWorkplace}/api/v1/availability/preferences/`,
        billingCodes: `${appUrls.apiWorkplace}/api/v1/billing_codes/`,
        clients: `${appUrls.apiWorkplace}/api/v1/clients/`,
        clientServices: `${appUrls.apiWorkplace}/api/v2/client-services/`,
        contactTypes: `${appUrls.apiWorkplace}/api/v1/contact-types/`,
        contacts: `${appUrls.apiWorkplace}/api/v1/contacts/`,
        directServices: `${appUrls.apiWorkplace}/api/v1/direct-services/`,
        documentTypes: `${appUrls.apiWorkplace}/api/v1/document-types/`,
        documents: `${appUrls.apiWorkplace}/api/v1/documents/`,
        ethnicities: `${appUrls.apiWorkplace}/api/v1/ethnicities/`,
        evaluations: `${appUrls.apiWorkplace}/api/v3/evaluations/`,
        evaluationActivities: `${appUrls.apiWorkplace}/api/v3/evaluations/:evaluation_uuid/activities/`,
        events: `${appUrls.apiWorkplace}/api/v1/events/`,
        jumbotron: `${appUrls.apiWorkplace}/api/v1/jumbotron/`,
        languages: `${appUrls.apiWorkplace}/api/v1/languages/`,
        lightTechCheck: `${appUrls.apiWorkplace}/api/v1/light-techcheck/`,
        locations: `${appUrls.apiWorkplace}/api/v1/locations/`,
        metrics: `${appUrls.apiWorkplace}/api/v1/metrics/`,
        metricsPoints: `${appUrls.apiWorkplace}/api/v1/metrics-points/`,
        notesSchemas: `${appUrls.apiWorkplace}/api/v1/notes/schemas/`,
        notesExports: `${appUrls.apiWorkplace}/api/v1/notes/exports/`,
        notesExportsOrganizations: `${appUrls.apiWorkplace}/api/v1/notes/exports/organizations/`,
        organizations: `${appUrls.apiWorkplace}/api/v1/organizations/`,
        providerTypes: `${appUrls.apiWorkplace}/api/v1/provider-types/`,
        providers: `${appUrls.apiWorkplace}/api/v1/providers/`,
        races: `${appUrls.apiWorkplace}/api/v1/races/`,
        records: `${appUrls.apiWorkplace}/api/v1/records/`,
        services: `${appUrls.apiWorkplace}/api/v1/services/`,
        serviceTypes: `${appUrls.apiWorkplace}/api/v1/service-types/`,
        upload: `${appUrls.apiWorkplace}/api/v1/upload/`,
        apollo: `${appUrls.apiWorkplace}/graphql/v1/`,

        platformFE: `${appUrls.platform}`,
        activity: `${appUrls.platform}/api/v1/activity/`,
        activityTag: `${appUrls.platform}/api/v1/tag/`,
        jiraIdea: `${appUrls.platform}/api/v3/jira/idea/`,
        room: `${appUrls.platform}/api/v1/room/`,
        roomResetWhiteboard: `${appUrls.platform}/api/v1/room/reset_whiteboard/`,
        video: `${appUrls.platform}/api/v1/video/`,
        recording: `${appUrls.platform}/api/v3/recording/`,

        eduClientsFE: `${appUrls.eduClients}`,
        locationListFE: `${appUrls.eduClients}/location/list`,
        billingFE: `${appUrls.eduClients}/billing`,
        clientsFE: `${appUrls.eduClients}/clients`,
        scheduleFE: `${appUrls.eduClients}/schedule`,
        landingFE: `${appUrls.eduClients}/landing`,

        techcheckFE: `${window.location.origin}/pl/setup/`,
        libraryFE: `${appUrls.library}`,
        therapyFE: `${appUrls.therapy}`,
        apiTherapyEssentialsFE: `${appUrls.apiEssentials}`,
        apiMizarFE: `${appUrls.apiMizar}`,

        helpDocsFE: `https://support.presence.com`,
        summarize: `${appUrls.chiron}/v1/summarize`,
        mainSiteFE: `https://www.presencelearning.com`,
        trainingFaqFE: `https://www.presencelearning.com/welcome-to-the-presencelearning-environment/`,
        copyrightFE: `https://www.presencelearning.com/about/copyright-policy/`,
        codeOfConductFE: `https://www.presencelearning.com/about/code-of-conduct/`,
        privacyPolicyFE: `https://www.presencelearning.com/about/platform-license-privacy-policy`,
        privacyPolicyAltFE: `https://www.presencelearning.com/about/privacy-policy/`,

        presenceMailFE: 'http://mail.presencelearning.com/',
        providerLoungeFE: 'https://presencelearning.com/lounge',
        techCheckFullReqFE: 'https://presencelearning.com/tech-requirements/',
        equipmentOrderFormFE: `https://www.tfaforms.com/4846346?email=`,
      };
    }

    this.plUrls.setUrlsDefaults(urls);
    this.plUrls.formUrls();
  }
}
