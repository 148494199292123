import { Injectable } from '@angular/core';
import { AuthenticationService } from './users/AuthenticationService';
import { User, GuestUser, UnauthenticatedUser } from './users/UserModels';
import { catchError, map } from 'rxjs/operators';

/**
 * Model for the current user data
 */
@Injectable({ providedIn: 'root' })
export class CurrentUserModel {
  user: User = null;
  jwt: string;
  onAuthenticationCallback?: (user: User) => void;
  constructor(private _authenticationService: AuthenticationService) {
    // Our default so that user is not undefined.
    this.user = new GuestUser();
  }

  /**
   * This will attempt to use the existing cookie to login the user. If a logged in user already exists,
   * it returns the currently logged in user.
   *
   * @return {nothing}
   */
  getAuthenticatedUser(withLearningProfile = false, allowGuest = false) {
    return this._authenticationService.authenticate(0, allowGuest).then(res => {
      if (res) {
        const { user, token } = res;
        this.user = user;
        this.jwt = token;
        this.onAuthenticationCallback?.(this.user);
        return user;
      }
      return null;
    });
  }

  useUnauthenticatedUser(params) {
    this.user = new UnauthenticatedUser(params);
  }

  onAuthentication(callback) {
    this.onAuthenticationCallback = callback;
  }

  updateUserImage() {
    this._authenticationService
      .getUserImage(this.user.uuid)
      .subscribe((imageUrl: any) => {
        this.user.profile_image_url = imageUrl;
      });
  }

  /**
   * See AuthenticationService.onLogout
   *
   * @param  {Function} callback
   */
  onLogout(callback) {
    this._authenticationService.onLogout(callback);
  }

  /**
   * See AuthenticationService.logoutgit
   */
  logout() {
    return this._authenticationService.logout().then(() => {
      this.user = new GuestUser();
      return this.user;
    });
  }
}
