import { props } from '@ngrx/store';
import { createAction } from '@common/utils';
import {
  Activity,
  AppConfigState,
  CentralContentType,
  LayoutMode,
} from './app.model';

const setCursorSharing = createAction(
  '/app/setCursorSharing',
  props<{ isCursorShared: boolean; isToggleDisabled: boolean }>(),
);

const setCursorSharingSuccess = createAction(
  '/app/setCursorSharingSuccess',
  props<{ isCursorShared: boolean; isToggleDisabled: boolean }>(),
);

const setClientMouseClick = createAction(
  '/app/setClientMouseClick',
  props<{ isClientClickMuted: boolean }>(),
);

const setClientMouseClickSuccess = createAction(
  '/app/setClientMouseClickSuccess',
  props<{ isClientClickMuted: boolean }>(),
);

const setLayoutMode = createAction(
  '/app/setLayoutMode',
  props<{ layoutMode: LayoutMode }>(),
);

const setLayoutModeSuccess = createAction(
  '/app/setLayoutModeSuccess',
  props<{ layoutMode: LayoutMode }>(),
);

const updateFromRemote = createAction(
  '/app/updateFromRemote',
  props<{ data: AppConfigState }>(),
);

const setIsCentralContentVisible = createAction(
  '/app/setIsCentralContentVisible',
  props<{ isVisible: boolean }>(),
);

const setIsCentralContentVisibleSuccess = createAction(
  '/app/setIsCentralContentVisibleSuccess',
  props<{ isVisible: boolean }>(),
);

const setCentralContent = createAction(
  '/app/setCentralContent',
  props<{ contentType: CentralContentType; contentDetail: any }>(),
);

const setCentralContentSuccess = createAction(
  '/app/setCentralContentSuccess',
  props<{ contentType: CentralContentType; contentDetail: any }>(),
);

const setCurrentDrawerActivity = createAction(
  '/app/setCurrentDrawerActivity',
  props<{ activity: Activity }>(),
);

const setCurrentDrawerActivitySuccess = createAction(
  '/app/setCurrentDrawerActivitySuccess',
  props<{ activity: Activity }>(),
);

const setTimestampTracking = createAction(
  '/app/setTimestampTracking',
  props<{ isTimestampTrackingDisabled: boolean }>(),
);

const setTimestampTrackingSuccess = createAction(
  '/app/setTimestampTrackingSuccess',
  props<{ isTimestampTrackingDisabled: boolean }>(),
);

const updateFailed = createAction('/app/updateFailed', props<{ error: any }>());

const admissionEventsChanged = createAction('/app/admissionEventsChanged');

export const AppActions = {
  setCursorSharing,
  setCursorSharingSuccess,
  setClientMouseClick,
  setClientMouseClickSuccess,
  setLayoutMode,
  setLayoutModeSuccess,
  updateFromRemote,
  updateFailed,
  setIsCentralContentVisible,
  setIsCentralContentVisibleSuccess,
  setCentralContent,
  setCentralContentSuccess,
  setCurrentDrawerActivity,
  setCurrentDrawerActivitySuccess,
  setTimestampTracking,
  setTimestampTrackingSuccess,
  admissionEventsChanged,
};
