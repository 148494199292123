import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '@common/models/users/AuthenticationService';
import { WaitingRoomConferenceService } from '../../waiting-room-conference.service';

import * as WaitingRoomActions from '../waiting-room.actions';

@Injectable()
export class WaitingRoomHostAdmissionEffects {
  admitGuest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingRoomActions.admitGuest),
      mergeMap(({ id, name }) => {
        return this.admit(id, name).pipe(
          map(() => WaitingRoomActions.admitGuestSuccess({ id, name })),
          catchError(err => {
            console.error('WaitingRoom admission error', { id, name, err });
            return of(
              WaitingRoomActions.admitGuestError({
                id,
                name,
                error: err,
              }),
            );
          }),
        );
      }),
    );
  });

  denyGuestAdmission$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingRoomActions.denyGuest),
      mergeMap(({ id, name }) => {
        return this.waitingRoomConference.deny(id).pipe(
          map(() => WaitingRoomActions.denyGuestSuccess({ id, name })),
          catchError(err => {
            console.error('WaitingRoom deny admission error', {
              id,
              name,
              err,
            });
            return of(WaitingRoomActions.denyGuestError({ error: err }));
          }),
        );
      }),
    );
  });

  private getToken(id: string, name: string) {
    return this.authenticationService
      .getStudentToken(name, id)
      .pipe(catchError(() => of<string>(null)));
  }

  private admit(id: string, name: string) {
    return this.getToken(id, name).pipe(
      switchMap(token => {
        if (!token) {
          return throwError(new Error('Error fetching student token'));
        }
        return this.waitingRoomConference.admit(id, token);
      }),
    );
  }

  constructor(
    private actions$: Actions,
    private waitingRoomConference: WaitingRoomConferenceService,
    private authenticationService: AuthenticationService,
  ) {}
}
