import { CardDisplayOption } from '@root/src/app/common/components/card-display-options/card-display-options.component';

export interface GoFishState {
  cardDisplay: CardDisplayOption | '';
  dealCount: number;
  xRayOn: boolean;
  isGameActive: boolean;
  cards: any[];
}

export const initialState: GoFishState = {
  cardDisplay: CardDisplayOption.ImageAndText,
  dealCount: 3,
  xRayOn: false,
  isGameActive: false,
  cards: [],
};
