import { createReducer, on } from '@ngrx/store';
import {
  AppConfigState,
  CentralContent,
  CentralContentType,
  LayoutMode,
} from './app.model';
import { AppActions } from './app.actions';

export const initialState: AppConfigState = {
  isClientClickMuted: false,
  isCursorShared: false,
  isToggleDisabled: false,
  layoutMode: LayoutMode.compact,
  currentCentralContent: {
    contentType: CentralContentType.Empty,
  },
  isTimestampTrackingDisabled: false,
};

const isSameCentralContent = (old: CentralContent, current: CentralContent) => {
  const { contentType: oldType, contentDetail: oldDetail } = old ?? {};
  const { contentType: currentType, contentDetail: currentDetail } =
    current ?? {};

  if (oldType !== currentType) {
    return false;
  }

  let detailIdKey: string;

  if (currentType === CentralContentType.QueueItem) {
    detailIdKey = 'activityId';
  }

  if (currentType === CentralContentType.Game) {
    detailIdKey = 'name';
  }

  if (detailIdKey) {
    const oldDetailId = oldDetail?.[detailIdKey];
    const currentDetailId = currentDetail?.[detailIdKey];

    return oldDetailId === currentDetailId;
  }
  return oldDetail === currentDetail;
};

export const reducer = createReducer(
  initialState,
  on(
    AppActions.setCursorSharingSuccess,
    (state, { isCursorShared, isToggleDisabled }) => {
      return {
        ...state,
        isCursorShared,
        isToggleDisabled,
      };
    },
  ),
  on(AppActions.setClientMouseClickSuccess, (state, { isClientClickMuted }) => {
    return {
      ...state,
      isClientClickMuted,
    };
  }),
  on(AppActions.setLayoutModeSuccess, (state, { layoutMode }) => {
    return {
      ...state,
      layoutMode,
    };
  }),
  on(AppActions.updateFromRemote, (state, { data }) => {
    const newData = { ...data };
    if (
      isSameCentralContent(
        state.currentCentralContent,
        data?.currentCentralContent,
      )
    ) {
      delete newData.currentCentralContent;
    }
    const newState = {
      ...state,
      ...newData,
    };
    if (!newData.currentDrawerActivity) {
      newState.currentDrawerActivity = null;
    }
    return newState;
  }),
);
