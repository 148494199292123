import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ConferenceStatus } from '@common/conference';
import { WaitingRoomConferenceService } from '../../waiting-room-conference.service';

import * as WaitingRoomActions from '../waiting-room.actions';

@Injectable()
export class WaitingRoomHostKnockEffects {
  guestKnocked$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingRoomActions.startWaitingForGuests),
      switchMap(() => {
        return this.waitingRoomConference
          .onGuestKnocked()
          .pipe(
            map(knockData => WaitingRoomActions.guestKnocked({ knockData })),
          );
      }),
    );
  });

  guestKnockCanceled$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingRoomActions.startWaitingForGuests),
      switchMap(() => {
        return this.waitingRoomConference
          .onGuestKnockCanceled()
          .pipe(map(id => WaitingRoomActions.guestKnockCanceled({ id })));
      }),
    );
  });

  guestKnockingDisconnected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingRoomActions.startWaitingForGuests),
      switchMap(() => {
        return this.waitingRoomConference
          .onGuestKnockingDisconnected()
          .pipe(
            map(id => WaitingRoomActions.guestKnockingDisconnected({ id })),
          );
      }),
    );
  });

  notifyOnConnected$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WaitingRoomActions.startWaitingForGuests),
        switchMap(() => {
          return this.waitingRoomConference.getStatus().pipe(
            switchMap(status => {
              const isConnected = status === ConferenceStatus.connected;
              if (!isConnected) {
                return EMPTY;
              }
              return this.waitingRoomConference.notifyHostConnected();
            }),
          );
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  notifyOnRemoteConnected$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WaitingRoomActions.startWaitingForGuests),
        switchMap(() => {
          return this.waitingRoomConference.onRemoteConnected().pipe(
            concatLatestFrom(() => this.waitingRoomConference.getStatus()),
            switchMap(([{ id }, status]) => {
              const isConnected = status === ConferenceStatus.connected;
              if (!isConnected) {
                return EMPTY;
              }
              return this.waitingRoomConference.notifyHostConnected(id);
            }),
          );
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  constructor(
    private actions$: Actions,
    private waitingRoomConference: WaitingRoomConferenceService,
  ) {}
}
